import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';

export const validateSimpleField = <T extends Record<string, string | any>, K extends keyof T>(object: T, key: K, trim?: boolean) => {
  let fieldValue = object?.[key];
  
  if (trim && typeof fieldValue === 'string') {
    fieldValue = fieldValue.trim();
  }
  
  return !!fieldValue;
};

export const validateZipCodeField = (zipCode: string | undefined, country?: string) => {
  const fieldValue = zipCode?.trim?.();
  if (!fieldValue || !country) return false;
  if (postcodeValidatorExistsForCountry(country)) {
    return postcodeValidator(fieldValue, country);
  }
  // don't validate if country is not supported
  return true;
};

export const validateArrayField = <T extends Record<string, any | any[]>, K extends keyof T>(object: T, key: K, length: number) => {
  const arrayField: any[] = object?.[key];
  return !!(arrayField && arrayField.length >= length);
};
export const validateArrayFieldStrictLength = <T extends Record<string, any | any[]>, K extends keyof T>(object: T, key: K, length: number) => {
  const arrayField: any[] = object?.[key];
  return !!(arrayField && arrayField.length === length);
};
