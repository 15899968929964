import isNil from 'lodash/isNil';
import { CountryCode } from 'postcode-validator';

const cintCountryNamesCodesMap: Record<string, CountryCode> = {
  UK: CountryCode.GB,
  Sweden: CountryCode.SE,
  Germany: CountryCode.DE,
  Denmark: CountryCode.DK,
  Finland: CountryCode.FI,
  Norway: CountryCode.NO,
  Spain: CountryCode.ES,
  Italy: CountryCode.IT,
  Hungary: CountryCode.HU,
  Greece: CountryCode.GR,
  Switzerland: CountryCode.CH,
  Belgium: CountryCode.BE,
  Netherlands: CountryCode.NL,
  Poland: CountryCode.PL,
  Estonia: CountryCode.EE,
  Lithuania: CountryCode.LT,
  Bulgaria: CountryCode.BG,
  Latvia: CountryCode.LV,
  Russia: CountryCode.RU,
  USA: CountryCode.US,
  China: CountryCode.CN,
  France: CountryCode.FR,
  Australia: CountryCode.AU,
  Ukraine: CountryCode.UA,
  Japan: CountryCode.JP,
  India: CountryCode.IN,
  Portugal: CountryCode.PT,
  Romania: CountryCode.RO,
  Brazil: CountryCode.BR,
  Austria: CountryCode.AT,
  Canada: CountryCode.CA,
  Turkey: CountryCode.TR,
  Mexico: CountryCode.MX,
  Kazakhstan: CountryCode.KZ,
  'Czech Republic': CountryCode.CZ,
  Argentina: CountryCode.AR,
  Chile: CountryCode.CL,
  Ecuador: CountryCode.EC,
  Peru: CountryCode.PE,
  Colombia: CountryCode.CO,
  Ireland: CountryCode.IE,
  Guatemala: CountryCode.GT,
  'Puerto Rico': CountryCode.PR,
  Venezuela: CountryCode.VE,
  Uruguay: CountryCode.UY,
  Slovakia: CountryCode.SK,
  'South Africa': CountryCode.ZA,
  'New Zealand': CountryCode.NZ,
  'Dominican Republic': CountryCode.DO,
  'Costa Rica': CountryCode.CR,
  Slovenia: CountryCode.SI,
  Serbia: CountryCode.RS,
  Croatia: CountryCode.HR,
  Malaysia: CountryCode.MY,
  Singapore: CountryCode.SG,
  Philippines: CountryCode.PH,
  Morocco: CountryCode.MA,
  Belarus: CountryCode.BY,
  Indonesia: CountryCode.ID,
  Thailand: CountryCode.TH,
  'Bosnia and Herzegovina': CountryCode.BA,
  Egypt: CountryCode.EG,
  Iceland: CountryCode.IS,
  Algeria: CountryCode.DZ,
  Oman: CountryCode.OM,
  Kuwait: CountryCode.KW,
  Israel: CountryCode.IL,
  Lebanon: CountryCode.LB,
  'South Korea': CountryCode.KR,
  Pakistan: CountryCode.PK,
  Iraq: CountryCode.IQ,
  Jordan: CountryCode.JO,
  Bahrain: CountryCode.BH,
  Vietnam: CountryCode.VN,
  Nigeria: CountryCode.NG,
  Paraguay: CountryCode.PY,
  'Saudi Arabia': CountryCode.SA,
  Azerbaijan: CountryCode.AZ,
  Macedonia: CountryCode.MK,
  Montenegro: CountryCode.ME,
  Taiwan: CountryCode.TW,
  Luxembourg: CountryCode.LU,
  Moldova: CountryCode.MD,
  Kenya: CountryCode.KE,
  Panama: CountryCode.PA,
  Cambodia: CountryCode.KH,
  'Sri Lanka': CountryCode.LK,
  Tunisia: CountryCode.TN,
  Albania: CountryCode.AL,
  Kosovo: CountryCode.XK,
  Bangladesh: CountryCode.BD,
  Tanzania: CountryCode.TZ,
  Mozambique: CountryCode.MZ,
  Ethiopia: CountryCode.ET,
  Zambia: CountryCode.ZM,
  Myanmar: CountryCode.MM,
  Cyprus: CountryCode.CY,
  Malta: CountryCode.MT,
  Laos: CountryCode.LA,
  Namibia: CountryCode.NA,
  Brunei: CountryCode.BN,
  Iran: CountryCode.IR,
  'El Salvador': CountryCode.SV,
  Nicaragua: CountryCode.NI,
  Sudan: CountryCode.SD,
  Lesotho: CountryCode.LS,
  Swaziland: CountryCode.SZ,
  Liberia: CountryCode.LR,
  Mauritius: CountryCode.MU,
  Honduras: CountryCode.HN,
  Senegal: CountryCode.SN,
  Nepal: CountryCode.NP
};

export const getCountryCodeByCintCountryName = (countryName?: string): string | undefined => {
  if (isNil(countryName)) {
    return;
  }
  return cintCountryNamesCodesMap[countryName];
};
