import axios from 'axios';


export const setupAxios = () => {
  setBaseUrl();
  setInterceptors();
}

function setBaseUrl() {
  axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
}

function setInterceptors() {
  axios.interceptors.response.use(
    (response) => {
      const { location: locationHeader } = response.headers;

      if (response.status === 201 && locationHeader) {
        return window.location.href = locationHeader;
      }

      const { data } = response;
      return data;
    },
    async (error) => {
      if (axios.isCancel(error)) {
        return Promise.reject(new axios.Cancel(error.message));
      }

      // Refresh access token
      // const originalRequest = error.config;
      //
      // if (error.response?.status === 403 && !originalRequest._retry && auth.currentUser) {
      //   originalRequest._retry = true;
      //
      //   const token = await auth.currentUser.getIdToken();
      //
      //   Cookies.set('auth-token', token);
      //
      //   axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      //
      //   return axios(originalRequest);
      // }

      if (error.response) {
        const { data } = error.response;
        return Promise.reject(data);
      }
    }
  );

  // axios.interceptors.request.use(
  //   async (config) => {
  //     let token = Cookies.get('auth-token');
  //
  //     if (!token && auth.currentUser) {
  //       token = await auth.currentUser.getIdToken(true);
  //       Cookies.set('auth-token', token);
  //     }
  //
  //     if (token) {
  //       config.headers = Object.assign({}, config.headers, { Authorization: `Bearer ${token}` });
  //     }
  //
  //     return config;
  //   },
  //   (error) => {
  //     // TODO: Clear cookies
  //     return Promise.reject(error);
  //   }
  // );
}
